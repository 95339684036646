import React from 'react';
import {Link} from "react-router-dom";
import {Animated} from "react-animated-css";
import TimeMap from '../js/time_map';
import Footer from './Footer';
import Header from "./Header";

class StartTest extends React.Component {
    render() {
        return (
            <div>
                <div className="RationalChoiceWrap text-center">
                    <Header/>
                    <div className="row now-gutters">

                        <Animated animationIn="fadeIn" animationInDuration={500} animationInDelay={200}
                                  className="st_choice choice choice-relaxing col-md-4 d-md-flex flex-md-column">
                            <div>
                                <Animated animationIn="bounceIn" animationInDuration={500} animationInDelay={550}>
                                    <Link to={{
                                        pathname: '/detect_face/',
                                        state: {
                                            rationalClass: TimeMap.LABELS.RELAX
                                        }
                                    }} className="btn btn-lg btn-primary">RILASSANTE ►</Link>
                                </Animated>
                                <p>Una vacanza tutta <br/> spiaggia e famiglia.</p>
                            </div>

                        </Animated>

                        <Animated animationIn="fadeIn" animationInDuration={500} animationInDelay={400}
                                  className="choice choice-exciting col-md-4 d-md-flex flex-md-column">

                            <div>
                                <Animated animationInDuration={500} animationInDelay={700} animationIn="bounceIn">

                                    <Link to={{
                                        pathname: '/detect_face/',
                                        state: {
                                            rationalClass: TimeMap.LABELS.FUNNY
                                        }
                                    }} className="btn btn-lg btn-primary">DIVERTENTE ►</Link>
                                </Animated>
                                <p>Una vacanza tunz tunz <br/> in pistaaaaaaaa.</p>
                            </div>

                        </Animated>

                        <Animated animationIn="fadeIn" animationInDuration={500}
                                  className="choice choice-romantic col-md-4 d-md-flex flex-md-column">
                            <div>

                                <Animated animationIn="bounceIn" animationInDuration={500} animationInDelay={400}>
                                    <Link to={{
                                        pathname: '/detect_face/',
                                        state: {
                                            rationalClass: TimeMap.LABELS.ROMANTIC
                                        }
                                    }} className="btn btn-lg btn-primary">ROMANTICA ►</Link>
                                </Animated>
                                <p>Una vacanza all’insegna <br/> dell’amore e del romanticismo.</p>
                            </div>

                        </Animated>

                        <div className="overborder">
                            <div className="topLeft"></div>
                            <div className="topRight"></div>
                            <h1>Quale Vacanza Scegli con la ragione?</h1>
                        </div>

                    </div>
                    <Footer/>
                </div>
            </div>
        );
    }
}

export default StartTest;

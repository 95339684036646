import React from 'react';
import Container from "./Container";
import Bowser from "bowser";
import AndInstruction from './AndroidInstructions';
import IOSFbInstruction from './iOSFbInstructions';
import IOSIgInstruction from './iOSIgInstructions';
import IOSChromeInstructions from './iOSChromeInstructions';

class HomeRouter extends React.Component {
    constructor(props) {
        super(props);
        const browser = Bowser.getParser(window.navigator.userAgent);
        this.state = {
            isInApp: this.isInApp(browser.getUA().toLowerCase()),
            isInFb: this.isFb(browser.getUA().toLowerCase()),
            isInIg: this.isIg(browser.getUA().toLowerCase()),
            isiOS: browser.getOSName() === 'iOS',
            isinChrome: browser.getBrowserName() === 'Chrome'
        };
    }

    isInApp(ua) {
        return this.isFb(ua) || this.isIg(ua);
    }

    isFb(ua){
        return ua.indexOf("fban") > -1 || ua.indexOf("fbav") > -1;
    }

    isIg(ua){
        return ua.indexOf("instagram") > -1;
    }

    render() {
        let content;
        if (this.state.isInFb && this.state.isiOS) {
            content = (<IOSFbInstruction/>);
        } else  if (this.state.isInIg && this.state.isiOS) {
            content = (<IOSIgInstruction/>);
        }else if (this.state.isInApp) {
            content = (<AndInstruction/>);
        } else if (this.state.isiOS && this.state.isinChrome) {
            content = (<IOSChromeInstructions/>);
        } else {
            content = (<Container/>);
        }

        return (
            <div>
                {content}
            </div>
        );
    };
}

export default HomeRouter;
import React from 'react';
import '../styles/AlpHeader.scss';
import AlpitourLogo from '../media/alpitour_world.png';


class Header extends React.Component {
    render() {
        return (
            <div className="top-container w-100 text-left">
                <a href="//www.alpitour.it" target="_blank"><img src={AlpitourLogo}
                                                                 className="brand-logo img-responsive" width="150px"/></a>
            </div>
        );
    }
}

export default Header;

import React from 'react';
import {Animated} from "react-animated-css";

import {
    FacebookShareButton,
    TwitterShareButton,
    FacebookIcon,
    TwitterIcon,
} from 'react-share';


import CompanyImg from '../../media/logo-bravo-club@2x.png';
import Footer from "../Footer";

var CompanyName = 'Bravo Club';
var CompanyLink = 'https://www.alpitour.it/bravoclub?utm_source=morphcast&utm_medium=distribution&utm_campaign=ragioneemozione';
var shareUrl = window.location.origin + "/relax-funny.html";


class Result extends React.Component {
    render() {
        return (
            <div className="Res">
                <div className="ResultWrap  RelaxFunnyWrap">


                    <div className="container-fluid d-flex flex-column">
                        <div className="row no-gutters">
                            <div className="col-md-4 col-xl-3 decision-left">
                                <Animated animationIn="fadeIn" animationInDuration={500}
                                          className="d-md-flex justify-content-end">

                                    <p className="h5">Con la <strong>RAGIONE</strong> hai scelto una vacanza tutta
                                        spiaggia e famiglia.</p>
                                </Animated>
                            </div>
                            <div className="col-md-7 decision-right">
                                <Animated animationIn="fadeIn" animationInDuration={500} className="decision-inner">

                                    <Animated animationIn="fadeInUp" animationInDuration={500} animationInDelay={200}>
                                        <p className="h3 mb-4">Ma le tue <strong>EMOZIONI</strong> dicono</p>
                                    </Animated>
                                    <Animated animationIn="fadeInUp" animationInDuration={500} animationInDelay={400}>

                                        <h1>ACCHIAPPALIKE</h1>
                                    </Animated>
                                    <Animated animationIn="fadeInUp" animationInDuration={500} animationInDelay={600}>
                                        <p className="h4">Piaci piaci piaci. E ti piace piacere. Durante un corso di
                                            kite full immersion o full immersion nella barriera corallina. Non
                                            dimenticarti la fotocamera sennò addio carriera da influencer.</p>
                                    </Animated>

                                    <Animated animationIn="fadeInLeft" animationInDuration={500}
                                              animationInDelay={1000}>
                                        <a href={CompanyLink}><img className="logo-result" src={CompanyImg}
                                                                   alt={CompanyName}/></a>


                                        <a href={CompanyLink} className="btn btn-warning btn-lg">Scopri di più</a>
                                    </Animated>

                                </Animated>
                            </div>
                        </div>


                        <div className="share share mt-md-auto text-md-right py-4">
                            <Animated animationIn="fadeInLeft" animationInDuration={500} animationInDelay={1200}>
                                <h5><span>CONDIVIDI IL RISULTATO</span> <FacebookShareButton
                                    url={shareUrl}><FacebookIcon size={40} round={true}/></FacebookShareButton>
                                    <TwitterShareButton url={shareUrl}><TwitterIcon size={40}
                                                                                    round={true}/></TwitterShareButton>
                                </h5>
                            </Animated>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        );
    }
}

export default Result;
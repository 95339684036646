import React from 'react';
import AlpitourworldImg from '../media/alpitour_world@2x.png';
import LogoragioneImg from '../media/logo-ragione-emozione@2x.png';
import ShareImg from '../media/shareios.png';

class AndInstructions extends React.Component {
    render() {
        return (


            <div className="StartTestWrap ig-fb d-flex flex-column align-items-center text-center">

                <div className="my-auto container pt-5">
                    <img src={LogoragioneImg} alt="Ragione VS Emozione" width="536" height="487" className="logo-ragione" />

                    <h3>Come scegli davvero la tua vacanza? Segui le istruzioni per scoprirlo!</h3>
                    <br/>
                    <p><strong className="display-4"> 1</strong><br/> Clicca su <img src={ShareImg} alt="Menu" width="25" /> <strong>IN BASSO</strong><br/><br/>
                        <strong className="display-4"> 2</strong><br/> Clicca su <strong>APRI CON SAFARI</strong></p>


                </div>


                <footer className="py-5">
                    <img src={AlpitourworldImg} alt="alpitour world" width="209" height="57" />
                </footer>

            </div>
        );
    }
}

export default AndInstructions;

import React from 'react';
import { HashRouter } from 'react-router-dom';
import ReactDOM from 'react-dom';
import './index.scss';
import * as serviceWorker from './serviceWorker';
import HomeRouter from "./pages/HomeRouter";
import createHistory from 'history/createBrowserHistory';
import ReactGA from 'react-ga';

//import ScrollToTop from "./pages/ScrollToTop.js";
ReactGA.initialize('UA-42147344-1',{ // 'UA-76800970-19'
	gaOptions: {
		'linker': {
			'domains': ['alpitour.it', 'cataloghi.bravoclub.it']
		}
	}
});

const history = createHistory();
history.listen((location) => {
    window.scrollTo(0, 0);
	ReactGA.set({ page: location.pathname + location.search + location.hash });
	ReactGA.pageview(window.location.href);
});
ReactDOM.render(  
	<HashRouter basename="/">
			<HomeRouter />
	</HashRouter>,
	document.getElementById('root'));



// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import React from 'react';


class Footer extends React.Component {
    render() {
        return (
            <footer className="page-footer font-small blue py-2">

                <div className="step-indicator">
                    <span className="indicator-1">1</span>
                    <span className="indicator-2">2</span>
                    <span className="indicator-3">3</span>
                </div>


                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-8 text-sm-left d-flex flex-column justify-content-center">
                            <small>
                                <a href="https://www.alpitour.it/alpitour/privacy" target="_blank"
                                   rel="noopener noreferrer">Privacy Policy</a>
                                <a href="https://www.alpitour.it/alpitour/cookies" target="_blank"
                                   rel="noopener noreferrer">Cookie Policy</a>
                                <a href="https://www.alpitour.it/alpitour/condizioni-generali" target="_blank"
                                   rel="noopener noreferrer">Terms of use</a>
                            </small>
                        </div>
                        <div className="col-sm-8 text-sm-left d-flex flex-column justify-content-center">
                            <small><small>
                                <span>Copyright © 2018 Alpitour S.p.A.Sede legale: Via Lugaro, 15 - 10126 Torino - P.Iva
                                    02486000041</span><br/>
                                <span>Codice fiscale Numero di iscrizione al RI: 02933920015 - Capitale Sociale
                                    sottoscritto € 41.262.014
                                    i.v.</span>
                            </small></small>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}

export default Footer;

/* global CY */
export default class Loader {
    static loadAiSDK() {
        if (Loader.p == null) {
            Loader.p = loadScript('https://ai-sdk.morphcast.com/v1.8/ai-sdk.js').then(() => {
                Loader.CY = CY;
                return CY;
            });
        }
        return Loader.p;
    }

    static loadCY(camera) {
        if (Loader.CY_init == null) {
            Loader.CY_init = CY.loader()
                .addModule(CY.modules().FACE_EMOTION.name, {})
                .source(camera)
                .load()
                .then(({start}) => {
                    Loader.LOADED = true;
                    return camera.start().then(() => start());
                });
        }
        return Loader.CY_init;
    }

    static get loaded() {
        return Loader.LOADED;
    }

}

function loadScript(url) {
    return new Promise(resolve => {
        const script = document.createElement("script");
        script.type = "text/javascript";
        if (script.readyState) {  //IE
            script.onreadystatechange = function () {
                if (script.readyState === "loaded" || script.readyState === "complete") {
                    script.onreadystatechange = null;
                    resolve();
                }
            };
        } else {  //Others
            script.onload = function () {
                resolve();
            };
        }
        script.src = url;
        document.getElementsByTagName("head")[0].appendChild(script);
    });
}
import React from 'react';
import {Switch, Route, withRouter} from "react-router-dom";
import {TransitionGroup, CSSTransition} from "react-transition-group";
import styled from "styled-components";


import StartTest from './StartTest.js';
import RationalChoice from './RationalChoice.js';
import DetectFace from './DetectFace.js';
import Test from './Test.js';
import RomanticRomantic from './results/romantic_romantic';
import RomanticRelax from './results/romantic_relax';
import RomanticFunny from './results/romantic_funny';
import RelaxRomantic from './results/relax_romantic';
import RelaxRelax from './results/relax_relax';
import RelaxFunny from './results/relax_funny';
import FunnyFunny from './results/funny_funny';
import FunnyRomantic from './results/funny_romantic';
import FunnyRelax from './results/funny_relax';

function Container({location}) {
    return (
        <Wrapper>
            <TransitionGroup>
                <CSSTransition
                    key={location.key}
                    timeout={{enter: 400, exit: 400}}
                    onExited={() => window.scrollTo(0, 0)}
                    classNames="fade"
                >
                    <Switch location={location}>
                        <Route path="/" exact component={StartTest}/>
                        <Route path="/rational_choice/" component={RationalChoice}/>
                        <Route path="/detect_face/" component={DetectFace}/>
                        <Route path="/test/" component={Test}/>

                        <Route path="/relax-relax/" component={RelaxRelax}/>
                        <Route path="/relax-funny/" component={RelaxFunny}/>
                        <Route path="/relax-romantic/" component={RelaxRomantic}/>
                        <Route path="/funny-relax/" component={FunnyRelax}/>
                        <Route path="/funny-funny/" component={FunnyFunny}/>
                        <Route path="/funny-romantic/" component={FunnyRomantic}/>
                        <Route path="/romantic-relax/" component={RomanticRelax}/>
                        <Route path="/romantic-funny/" component={RomanticFunny}/>
                        <Route path="/romantic-romantic/" component={RomanticRomantic}/>
                    </Switch>
                </CSSTransition>
            </TransitionGroup>
        </Wrapper>
    );
}


const Wrapper = styled.div`
  .fade-enter {
    opacity: 0.01;
  }

  .fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 400ms ease-in;
    position: fixed;
    top: 0;
    z-index: 1;
    left:0;
    right:0;
  }

  .fade-exit {
    opacity: 1;
  }

  .fade-exit.fade-exit-active {
    opacity: 0.01;
    transition: opacity 400ms ease-in;
    position: relative;
    z-index: 0;
  }

  div.transition-group {
    position: relative;
  }

  section.route-section {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
  }
`;

export default withRouter(Container);

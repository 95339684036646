/*
    0:00 -> 8:10 | RELAX
    8:10 -> 17:25 | FUNNY
    17:25 -> 28:23 | ROMANTICA
    29:80 -> 37:23 | FUNNY
    39:50 -> 45:65 | RELAX
    47:70-> END | ROMANTICA
*/

const LABELS = {
    RELAX: "Rilassante",
    FUNNY: "Divertente",
    ROMANTIC: "Romantica"
};

const TIMES = {
    [LABELS.RELAX]: 0,
    [LABELS.FUNNY]: 0,
    [LABELS.ROMANTIC]: 0,
};

const TIMELINE = [
    {
        s: 0,
        e: 8100,
        flag: LABELS.RELAX
    },
    {
        s: 8100,
        e: 17250,
        flag: LABELS.FUNNY
    },
    {
        s: 17250,
        e: 28230,
        flag: LABELS.ROMANTIC
    },
    {
        s: 29800,
        e: 37230,
        flag: LABELS.FUNNY
    },
    {
        s: 39500,
        e: 45650,
        flag: LABELS.RELAX
    },
    {
        s: 47700,
        e: 53000,
        flag: LABELS.ROMANTIC
    }
];


TIMELINE.forEach(e => TIMES[e.flag] += ((e.e / 1000) - (e.s / 1000)));

export default class TimeMap {
    static get(ct) {
        const index = TIMELINE.findIndex((el) => el.s <= ct && el.e >= ct);

        return index > -1 ? TIMELINE[index].flag : undefined;
    }

    static get LABELS() {
        return LABELS;
    }

    static get TIMES() {
        return TIMES;
    }
}
import React from 'react';
import {Link} from "react-router-dom";
import {Animated} from "react-animated-css";
import AlpitourworldImg from '../media/alpitour_world@2x.png';
import LogoragioneImg from '../media/logo-ragione-emozione@2x.png';
import Header from "./Header";
import Footer from "./Footer";




class StartTest extends React.Component {
    render() {
        return (
	        <div>
	            <div className="StartTestWrap d-flex flex-column align-items-center text-center">
		            <Header/>
	            	<div className="my-auto container pt-xs-2 pt-sm-2 pt-md-5 pt-lg-5 pt-xl-5">
	            	<Animated animationIn="fadeInUp" animationInDuration={500}>
		            	<img src={LogoragioneImg} alt="Ragione VS Emozione" width="536" height="487" className="logo-ragione" />
		            </Animated>	
		            	
		            	<Animated animationIn="fadeInUp" animationInDuration={500} animationInDelay={300}>
		            	<h1>Come scegli davvero la tua vacanza?</h1>
		            	</Animated>
		            	
		            	<Animated animationIn="bounceIn" animationInDuration={500} animationInDelay={600}>
	
		                <Link to="/rational_choice/" className="btn btn-lg btn-light mb-4">FAI IL TEST ►</Link>
		                </Animated>
	                
	                </div>

					<Footer/>
	            </div>
            </div>
        );
    }
}

export default StartTest;

import React from 'react';
import Loader from '../js/sdk_loader';
import {Animated} from "react-animated-css";
import IconCamera from '../media/icon-camera.svg';
import IconAudio from '../media/icon-speaker.svg';
import IconSmile from '../media/icon-smile.svg';
import Footer from './Footer';
import TimeMap from '../js/time_map';
import {Link} from "react-router-dom";
import Header from "./Header";


class DetectFace extends React.Component {
    constructor(props) {
        super(props);
        if (props.location.state == null || props.location.state.rationalClass == null) {
            this.props.history.push('/');
        } else {
            this.state = {
                showInstructions: true,
                rationalClass: props.location.state.rationalClass,
                showError: false
            };
        }
    }

    sensing(CY) {
        const cb = evt => {
            const emo = evt.detail.output.emotion;
            if (emo.Happy > 0.4) {
                window.removeEventListener(CY.modules().FACE_EMOTION.eventName, cb);
                this.props.history.push({
                    pathname: '/test/',
                    state: {
                        rationalClass: this.state.rationalClass
                    }
                })
            }
        };
        window.addEventListener(CY.modules().FACE_EMOTION.eventName, cb);
    }

    continueClick() {
        this.setState({showInstructions: false});
        let camera;
        Loader.loadAiSDK()
            .then(CY => {
                camera = CY.getUserMediaCameraFactory().createCamera();
                return Loader.loadCY(camera);
            })
            .then(() => {
                this.refs.videoRef.srcObject = camera.stream;
                this.refs.videoRef.play();
                setTimeout(() => this.sensing(Loader.CY), 500);
            })
            .catch(() => {
                this.setState({showError: true});
            });
    }


    render() {
        let content;
        if (this.state && this.state.showInstructions) {
            content = this.renderInstrucitons();
        } else if (this.state && this.state.showError) {
            content = this.renderError();
        } else {
            content = this.renderContent();
        }
        return (
            <div>
                <div className="DetectFaceWrap text-center">
                    <Header/>
                    {content}
                    <Footer/>
                </div>
            </div>
        );
    }

    renderInstrucitons() {
        return <div className="Instructions pb-5 d-md-flex flex-md-column justify-content-center">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-4 step">
                        <Animated animationIn="fadeInUp" animationInDuration={500} animationInDelay={100}>
                            <div>
                                <img src={IconCamera} alt="Accendi la camera" width="80" height="80"/>
                                <p>1. Clicca su continua e attiva la tua camera</p>
                            </div>
                        </Animated>
                    </div>
                    <div className="col-md-4 step">
                        <Animated animationIn="fadeInUp" animationInDuration={500} animationInDelay={400}>
                            <div>
                                <img src={IconAudio} alt="Accendi l'audio" width="80" height="80"/>
                                <p>2. Che vacanza è senza colonna sonora? Accendi l’audio!</p>
                            </div>
                        </Animated>
                    </div>
                    <div className="col-md-4 step">
                        <Animated animationIn="fadeInUp" animationInDuration={500} animationInDelay={700}>
                            <div>
                                <img src={IconSmile} alt="Osserva il video" width="80" height="80"/>
                                <p>3. osserva il video E Rilassati come se fossi già in spiaggia.</p>
                            </div>
                        </Animated>
                    </div>
                </div>
                <Animated animationIn="bounceIn" animationInDuration={500} animationInDelay={1000}>
	                            <span className="continue_btn btn btn-lg btn-primary"
                                      onClick={() => this.continueClick()}>CONTINUA  ►</span>
                </Animated>
            </div>
        </div>;
    }

    renderError() {
        let path;
        switch (this.state.rationalClass) {
            case TimeMap.LABELS.ROMANTIC:
                path = '/romantic-romantic/';
                break;
            case TimeMap.LABELS.RELAX:
                path = '/relax-relax/';
                break;
            case TimeMap.LABELS.FUNNY:
                path = '/funny-funny/';
                break;
            default:
        }
        return <div className="FaceDetect d-flex flex-column justify-content-center">
            <div className="container">
                <Animated animationIn="fadeInUp" animationInDuration={500}>
                    <div className="col-md-8 offset-md-2 col-lg-6 offset-lg-3">
                        <p>Non è stato possibile eseguire il test perché non siamo riusciti ad accedere alla camera.
                            Clicca <strong>"CONTINUA"</strong> per scoprire di più sul tipo di vacanza che hai scelto.
                        </p>
                        <br/>
                        <Link to={path} className="btn btn-lg btn-light">CONTINUA ►</Link>

                    </div>
                </Animated>
            </div>
        </div>;

    }

    renderContent() {
        return <div className="FaceDetect d-flex flex-column justify-content-center">
            <div className="container" style={{"margin": "100px auto"}}>
                <Animated animationIn="fadeInUp" animationInDuration={500}>
                    <div className="col-md-8 offset-md-2 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4 ">
                        <div className="video-container">
                            <video ref="videoRef" className="CameraVideo" playsInline muted autoPlay={true}/>
                        </div>
                    </div>
                    <p className="h2">Vuoi guardare il video?</p>
                    <p className="display-1">Sorridi!</p>
                </Animated>
            </div>
        </div>;
    }

}

export default DetectFace;

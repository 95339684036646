import React from 'react';
import {Animated} from "react-animated-css";

import {
  FacebookShareButton,
  TwitterShareButton,
  FacebookIcon,
  TwitterIcon,
} from 'react-share';


import CompanyImg from '../../media/logo-alpitour@2x.png';
import Footer from "../Footer";
var  CompanyName = 'Alpitour';
var  CompanyLink = 'https://www.alpitour.it/?utm_source=morphcast&utm_medium=distribution&utm_campaign=ragioneemozione';
var  shareUrl = window.location.origin + "/funny-relax.html";



class Result extends React.Component {
    render() {
        return (
	        <div className="Res">
	            <div className="ResultWrap FunnyRelaxWrap">
	                <div className="container-fluid d-flex flex-column">
		                <div className="row no-gutters">
			                <div className="col-md-4 col-xl-3 decision-left">         
			                	<Animated animationIn="fadeIn" animationInDuration={500} className="d-md-flex justify-content-end">

			                		<p className="h5">Con la <strong>RAGIONE</strong> hai scelto una vacanza tunz tunz in pistaaaaaaa</p>
			                	</Animated>
			                </div>
			                <div className="col-md-7 decision-right">
			                	<Animated animationIn="fadeIn" animationInDuration={500} className="decision-inner">
				                
				                	<Animated animationIn="fadeInUp" animationInDuration={500} animationInDelay={200}>
					                <p className="h3 mb-4">Ma le tue <strong>EMOZIONI</strong> dicono</p>
					                </Animated>
					                <Animated animationIn="fadeInUp" animationInDuration={500} animationInDelay={400}>

					                <h1>CAPOBRANCO</h1>
					                </Animated>
					                <Animated animationIn="fadeInUp" animationInDuration={500} animationInDelay={600}>
					                <p className="h4">La tua parola d’ordine è: tutto sotto controllo. Dall’ora del bagno, a quella del pisolino. stabilisci le regole e decidi se, e quando infrangerle. Gli altri ti seguiranno.</p>
					                </Animated>
					                
					                <Animated animationIn="fadeInLeft" animationInDuration={500} animationInDelay={1000}>
					                <a href={CompanyLink}><img className="logo-result" src={CompanyImg} alt={CompanyName} /></a>
					                
					                
					                <a href={CompanyLink} className="btn btn-primary btn-lg">Scopri di più</a>
					                </Animated>
					                
				                </Animated>
			                </div>
		                </div>
		                
		                
		                <div className="share mt-md-auto text-md-right py-4">
		                <Animated animationIn="fadeInLeft" animationInDuration={500} animationInDelay={1200}>
		                <h5><span>CONDIVIDI IL RISULTATO</span> <FacebookShareButton url={shareUrl}><FacebookIcon size={40} round={true} /></FacebookShareButton>
		                <TwitterShareButton url={shareUrl}><TwitterIcon size={40} round={true} /></TwitterShareButton></h5>
		                </Animated>
		                </div>
	                </div>
	            </div>
				<Footer/>
            </div>
        );
    }
}

export default Result;
import React from 'react';
import TimeMap from '../js/time_map';
import Loader from "../js/sdk_loader";
import video from '../media/video.mp4';
import banner from '../media/banner.jpg';
import Header from "./Header";
import Footer from "./Footer";

class Test extends React.Component {
    constructor(props) {
        super(props);
        this.unregister = e => e;
        if (!Loader.LOADED || props.location.state == null || props.location.state.rationalClass == null) {
            this.props.history.push('/');
        } else {
            this.rationalClass = props.location.state.rationalClass;
            this.score = {[TimeMap.LABELS.RELAX]: 0, [TimeMap.LABELS.FUNNY]: 0, [TimeMap.LABELS.ROMANTIC]: 0};
            this.counters = {[TimeMap.LABELS.RELAX]: 0, [TimeMap.LABELS.FUNNY]: 0, [TimeMap.LABELS.ROMANTIC]: 0};
            this.scores = [];
            this.happyThreshold = 0.4;
            this.surpriseThreshold = 0.4;
            this.fearThreshold = 0.4;
            this.disgustThreshold = 0.4;
            this.sensing(Loader.CY);
            setTimeout(() => {
                this.refs.video.onended = () => this.printScores();
                this.refs.video.onclick = () => {
                    this.refs.video.play();
                }
            }, 100);
        }
    }

    render() {
        return (
	        <div>
            <div className="TestWrap">
                <Header/>
                <video playsInline autoPlay={true} ref="video" className="video" poster={banner}>
                    <source src={video} type="video/mp4"/>
                </video>
            </div>
            </div>
        );
    }

    componentWillUnmount() {
        this.unregister();
    }

    sensing(CY) {
        const cb = (evt) => {
            if (this.refs.video.paused) return;
            const emo = evt.detail.output.emotion;
            const ct = this.refs.video.currentTime * 1000;
            const label = TimeMap.get(ct);
            if (label != null) {
                this.counters[label]++;
                if (emo.Happy > this.happyThreshold) {
                    this.score[label] += emo.Happy;
                    //console.log("SCORING +" + emo.Happy.toFixed(2) + ": on " + label + " at t " + ct);
                } else if (emo.Surprise > this.surpriseThreshold) {
                    this.score[label] += emo.Surprise;
                    //console.log("SCORING +" + emo.Surprise.toFixed(2) + ": on " + label + " at t " + ct);
                } else if (emo.Fear > this.fearThreshold) {
                    this.score[label] -= emo.Fear;
                    //console.log("SCORING -" + emo.Fear.toFixed(2) + ": on " + label + " at t " + ct);
                } else if (emo.Disgust > this.disgustThreshold) {
                    this.score[label] -= emo.Disgust;
                    //console.log("SCORING -" + emo.Disgust.toFixed(2) + ": on " + label + " at t " + ct);
                }
            }
        };
        window.addEventListener(CY.modules().FACE_EMOTION.eventName, cb);
        this.unregister = () => window.removeEventListener(CY.modules().FACE_EMOTION.eventName, cb);
    }


    calcScore(LABEL) {
        return this.score[LABEL] / this.counters[LABEL];
    }

    printScores() {
        this.score[TimeMap.LABELS.FUNNY] = this.calcScore(TimeMap.LABELS.FUNNY);
        this.score[TimeMap.LABELS.RELAX] = this.calcScore(TimeMap.LABELS.RELAX);
        this.score[TimeMap.LABELS.ROMANTIC] = this.calcScore(TimeMap.LABELS.ROMANTIC);

        const funny = {
            flag: TimeMap.LABELS.FUNNY,
            s: this.score[TimeMap.LABELS.FUNNY]
        };

        const relax = {
            flag: TimeMap.LABELS.RELAX,
            s: this.score[TimeMap.LABELS.RELAX]
        };
        const romantic = {
            flag: TimeMap.LABELS.ROMANTIC,
            s: this.score[TimeMap.LABELS.ROMANTIC]
        };
        this.scores.push(funny);
        this.scores.push(relax);
        this.scores.push(romantic);

        this.scores.sort((a, b) => {
            return b.s - a.s
        });

        console.log("EMOTIONS: ", this.scores, "\n---------");

        switch (this.rationalClass) {
            case TimeMap.LABELS.RELAX:
                switch (this.scores[0].flag) {
                    case TimeMap.LABELS.RELAX:
                        this.props.history.push('/relax-relax/');
                        break;
                    case TimeMap.LABELS.FUNNY:
                        this.props.history.push('/relax-funny/');
                        break;
                    case TimeMap.LABELS.ROMANTIC:
                        this.props.history.push('/relax-romantic/');
                        break;
                    default:
                }
                break;
            case TimeMap.LABELS.FUNNY:
                switch (this.scores[0].flag) {
                    case TimeMap.LABELS.RELAX:
                        this.props.history.push('/funny-relax/');
                        break;
                    case TimeMap.LABELS.FUNNY:
                        this.props.history.push('/funny-funny/');
                        break;
                    case TimeMap.LABELS.ROMANTIC:
                        this.props.history.push('/funny-romantic/');
                        break;
                    default:
                }
                break;
            case TimeMap.LABELS.ROMANTIC:
                switch (this.scores[0].flag) {
                    case TimeMap.LABELS.RELAX:
                        this.props.history.push('/romantic-relax/');
                        break;
                    case TimeMap.LABELS.FUNNY:
                        this.props.history.push('/romantic-funny/');
                        break;
                    case TimeMap.LABELS.ROMANTIC:
                        this.props.history.push('/romantic-romantic/');
                        break;
                    default:
                }
                break;
            default:
        }
    }
}

export default Test;
